<template>
  <div id="app-course-add" class="app-add">
    <loading :loading="loading" text="提交中"></loading>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="base">
        <el-form ref="form" label-width="120px">
          <el-form-item label="课程名称" :required="true">
            <el-input type="text"  v-model="course.name"  placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="课时数量" :required="true">
            <el-input type="number"  v-model="course.lessonNum"  placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="课程排序" :required="true">
            <el-input type="number"  v-model="course.sort"  placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="课程助教">
            <assistant-select ref="assistantSelect"  @parentEvent="getAssistantId"></assistant-select>
          </el-form-item>
          <el-form-item label="课程讲师">
            <el-select v-model="course.teacherIds" multiple placeholder="请选择" style="width: 100%">
              <el-option
                  v-for="teacher in teachers"
                  :key="teacher.id"
                  :label="teacher.name"
                  :value="teacher.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属科目">
            <el-select v-model="course.subjectIds" multiple placeholder="请选择" style="width: 100%">
              <el-option
                  v-for="subject in subjects"
                  :key="subject.id"
                  :label="subject.name"
                  :value="subject.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属专业" v-show="isShowMajor">
              <el-checkbox v-for="major in majors" v-model="course.majorIds" :label="major.id">
                {{major.name}}
              </el-checkbox >
          </el-form-item>
          <el-form-item label="所属年份">
            <el-radio v-for="year in years" v-model="course.yearId" :label="year.id">
              {{year.name}}
            </el-radio>
          </el-form-item>
          <el-form-item label="相关题型" v-show="isShowQuestionType">
            <el-radio v-for="questionType in questionTypes" v-model="course.typeId" :label="questionType.id">
              {{questionType.name}}
            </el-radio>
          </el-form-item>
          <upload-form-item ref="smallPicUrl"  @parentEvent="getSmallPicUrl"></upload-form-item>
          <upload-form-item ref="bigPicUrl"  @parentEvent="getBigPicUrl"></upload-form-item>
          <el-form-item label="课程介绍">
            <quill-editor class="editor" @focus="quill = this" v-model="course.content" :options="quillOption"></quill-editor>
            <div class="layui-input-block">
              <span style="color:#666;padding:5px;display: block;">图宽750、图高不限、单图不超500K</span>
            </div>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="销售信息" name="sale">
        <el-form ref="form-sale" label-width="120px">
          <el-form-item label="市场价格" :required="true">
            <el-input type="number"  v-model="course.marketPrice"  placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="售卖价格" :required="true">
            <el-input type="number"  v-model="course.price"  placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="已售基数" :required="true">
            <el-input type="number"  v-model="course.buyNum"  placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="课程周期" :required="true" v-show="isShowTime == true">
            <el-date-picker
                v-model="dateTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="有效期至" :required="true">
            <el-date-picker
                v-model="course.expire"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="APP/网站上架" v-show="false">
            <el-radio v-model="course.isSale" :label="false">否</el-radio>
            <el-radio v-model="course.isSale" :label="true">是</el-radio>
          </el-form-item>
          <el-form-item label="小程序上架">
            <el-radio v-model="course.isMpSale" :label="false">否</el-radio>
            <el-radio v-model="course.isMpSale" :label="true">是</el-radio>
          </el-form-item>
          <el-form-item :label="hotRecommendLabel">
            <el-radio v-model="course.isHotRecommend" :label="false">否</el-radio>
            <el-radio v-model="course.isHotRecommend" :label="true">是</el-radio>
          </el-form-item>
          <el-form-item label="免费课推荐" v-show="isShowFreeRecommend">
            <el-radio v-model="course.isFreeRecommend" :label="false">否</el-radio>
            <el-radio v-model="course.isFreeRecommend" :label="true">是</el-radio>
          </el-form-item>
          <el-form-item label="邮寄资料">
            <el-radio v-model="course.isPost" :label="false">不需要</el-radio>
            <el-radio v-model="course.isPost" :label="true">需要</el-radio>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-button @click="submit()" id="layuiadmin-app-form-submit" style="display: none">提交</el-button>
    </el-tabs>
  </div>
</template>

<script>
import AssistantSelect from "@/components/AssistantSelect";
import {
  QuestionTypeEnum,
  ProjectEnum,
  UrlEnum,
  getLocalProjectId,
  request,
  TipEnum,
  CourseCategoryEnum
} from "../../../public/js/common-vue";
import UploadFormItem from "@/components/UploadFormItem";
import {quillEditor} from "vue-quill-editor";
import quillConfig from "@/assets/js/quill-config";
import Loading from "@/components/Loading";
export default {
  name: "CourseSave",
  components: {Loading, UploadFormItem, AssistantSelect,quillEditor},
  data() {
    return {
      loading: false,
      id:0,
      activeName: 'base',
      years:[],
      questionTypes:[],
      teachers:[],
      subjects:[],
      quillOption: quillConfig,
      majors:[],
      isShowMajor: request("major") == 'true' ? true : false,
      isShowQuestionType:request("questionType") == 'true' ? true : false,
      isShowTime:false,
      dateTime: [new Date(), new Date()],
      isShowFreeRecommend:request('categoryId') == CourseCategoryEnum.FK_MATCH ? false : true,
      hotRecommendLabel: request('categoryId') == CourseCategoryEnum.FK_MATCH ? "小程序首页推荐" : "热门课推荐",
      course:{
        name:'',
        lessonNum:'',
        isHotRecommend:false,
        isFreeRecommend:false,
        yearId:'',
        typeId:'',
        teacherIds:[],
        subjectIds:[],
        majorIds:[],
        marketPrice:'',
        price:'',
        buyNum:0,
        isSale:false,
        isPost:false,
        openChatGroup:false,
        maxUserNum:0,
        groupPicUrl:'',
        nicknamePrefix:'',
        smallPicUrl:'',
        bigPicUrl:'',
        expire: '',
        sort:'',
        projectId:getLocalProjectId(),
        categoryId:request("categoryId"),
        content:'',
        assistantId:0,
        isMpSale:false
      }
    }
  },
  methods: {
    getGroupPicUrl(data){
      this.course.groupPicUrl = data;
    },
    getSmallPicUrl(data){
      this.course.smallPicUrl = data;
    },
    getBigPicUrl(data){
      this.course.bigPicUrl = data;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getAssistantId(data){
      if(data == ''){
        data = 0;
      }
      this.course.assistantId = data;
    },
    getYears(){
      this.$http({
        method: "get",
        url: UrlEnum.YEARS+"?categoryId="+this.course.categoryId+"&projectId="+getLocalProjectId(),
      }).then((res) => {
        this.years = res.data.list;
        this.course.yearId = this.years[0].id;
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getQuestionTypes(){
      this.$http({
        method: "get",
        url: UrlEnum.QUESTION_TYPE + '?page=1&size=100&ids=1,2,3',
      }).then((res) => {
        this.questionTypes = res.data.list;
        this.course.typeId = this.questionTypes[0].id;
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getTeachers(){
      this.$http({
        method: "get",
        url: UrlEnum.TEACHER + '?page=1&size=100',
      }).then((res) => {
        this.teachers = res.data.list;
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getSubjects(){
      this.$http({
        method: "get",
        url: UrlEnum.SUBJECT + '?page=1&size=100&projectId='+getLocalProjectId()+'&parentId=0',
      }).then((res) => {
        this.subjects = res.data.list;
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getMajors:function(){
      this.$http({
        method: "get",
        url: UrlEnum.MAJORS+'?page=1&size=100',
      }).then((res) => {
        this.majors = res.data.list;
       }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    checkForm(){
      if(this.course.name == ''){
        this.showMsgError("请输入 基本信息->课程名称");
        return false;
      }
      if(this.course.lessonNum == ''){
        this.showMsgError("请输入 基本信息->课时数量");
        return false;
      }
      if(this.course.sort === ''){
        this.showMsgError("请输入 基本信息->课程排序");
        return false;
      }
      if(this.course.smallPicUrl == ''){
        this.showMsgError("请上传 基本信息->课程小图");
        return false;
      }
      if(this.course.bigPicUrl == ''){
        this.showMsgError('请上传 基本信息->课程大图');
        return false;
      }
      if(this.course.marketPrice === ''){
        this.showMsgError('请输入 销售信息->市场价格');
        return false;
      }
      if(this.course.price === ''){
        this.showMsgError('请输入 销售信息->售卖价格');
        return false;
      }
      if(this.course.buyNum === ''){
        this.showMsgError('请输入 销售信息->已售基数');
        return false;
      }
      if(this.dateTime == null){
        this.showMsgError("请选择 销售信息->课程周期");
        return false;
      }
      if(this.course.expire == ''){
        this.showMsgError('请输入 销售信息->有效期');
        return false;
      }
      if(this.course.maxUserNum === ''){
        this.showMsgError('请输入 群组信息->人数上限');
        return false;
      }
      if(this.course.openChatGroup == true && this.course.assistantId == 0){
        this.showMsgError("群组功能开启后需要指定课程助教");
        return false;
      }
      return true;
    },
    submit(){
      if(this.checkForm() == false){
        return;
      }
      this.course.expire = new Date(this.course.expire).valueOf();
      if(this.course.majorIds == null){
        this.course.majorIds = [];
      }
      if(this.course.teacherIds == null){
        this.course.teacherIds = [];
      }
      if(this.course.subjectIds == null){
        this.course.subjectIds = [];
      }
      this.course.startTime = this.moment(this.dateTime[0]).format("YYYY-MM-DD HH:mm:ss");
      this.course.endTime = this.moment(this.dateTime[1]).format("YYYY-MM-DD HH:mm:ss");
      this.course.isBranchShare = true;
      let url = UrlEnum.COURSE;
      let method = "post";
      if(this.id != 0){
        url += "/"+this.id;
        method = "put";
      }
      console.log(this.course);
      this.loading = true;
      this.$http({
        method: method,
        url: url,
        data: this.course,
      })
      .then((res) => {
        this.loading = false;
        this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
        setTimeout(function () {
          window.parent.postMessage({
            msg: '',
            key: 'refresh'
          }, '*')
        }, 800);
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    initData(){
      this.$http({
        method: "get",
        url: UrlEnum.COURSE + "/" + request('id'),
      }).then((res) => {
        this.course = res.data;
        this.course.expire = new Date(this.course.expire).format("yyyy-MM-dd");
        if(this.course.assistantId == 0){ this.course.assistantId = ''}
        this.$refs.assistantSelect.initData(this.course.assistantId);
        this.$refs.smallPicUrl.init("课程小图",true,'309 x 233',this.course.smallPicUrl);
        this.$refs.bigPicUrl.init("课程大图",true,'828 x 467',this.course.bigPicUrl);
        this.dateTime=[this.course.startTime,this.course.endTime];
        this.isShowTime = (this.course.categoryId == CourseCategoryEnum.FK_MATCH || this.course.categoryId == CourseCategoryEnum.FS_MATCH) ? true : false;
        this.isShowFreeRecommend = (this.course.categoryId == CourseCategoryEnum.FK_MATCH || this.course.categoryId == CourseCategoryEnum.FS_MATCH) ? false : true;
        this.hotRecommendLabel = (this.course.categoryId == CourseCategoryEnum.FK_MATCH || this.course.categoryId == CourseCategoryEnum.FS_MATCH) ? "小程序首页推荐" : "热门课推荐";
        console.log(this.course);
        this.getYears();
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    }
  },
  mounted() {
    if(request('id') != null && request('id') != ''){
      this.id = request('id');
      this.initData();
    }
    else{
      this.$refs.assistantSelect.initData(null);
      this.$refs.smallPicUrl.init("课程小图",true,'309 x 233',this.course.smallPicUrl);
      this.$refs.bigPicUrl.init("课程大图",true,'828 x 467',this.course.bigPicUrl);
      this.getYears();
    }

    this.getQuestionTypes();
    this.getTeachers();
    this.getSubjects();
    this.getMajors();
    this.isShowTime=(request("categoryId")  == CourseCategoryEnum.FK_MATCH || request("categoryId") == CourseCategoryEnum.FS_MATCH) ? true : false;
  }
}
</script>

<style>
@import "../../../static/css/add.css";
@import "../../../static/css/editor.css";
.editor{
  height: 500px;
  margin-bottom: 90px;
}
</style>

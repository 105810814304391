<template>
  <el-select v-model="assistantId" @change="handleChange" placeholder="请选择" style="width:100%">
    <el-option
        v-for="assistant in assistants"
        :key="assistant.id"
        :label="assistant.name"
        :value="assistant.id">
    </el-option>
  </el-select>
</template>

<script>
import {UrlEnum} from "../../public/js/common-vue";

export default {
  name: "AssistantSelect",
  data(){
    return{
      assistantId:'',
      assistants:[]
    }
  },
  methods:{
    initData(checkedVal){
      this.$http({
        method: "get",
        url: UrlEnum.ASSISTANT + '?page=1&size=100',
      }).then((res) => {
        this.assistants = res.data.list;
        if(checkedVal != null){
          this.assistantId = checkedVal;
        }
        this.handleChange();
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    handleChange(){
      this.$emit('parentEvent',this.assistantId)
    }
  },
  mounted() {}
}
</script>

<style scoped>

</style>
